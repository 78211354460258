<template>
    <div>
        <div v-for="(vat,key) in row.item.vats">
            {{ key }}% - {{ vat | priceFormat }} &euro;
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>